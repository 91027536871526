import {FormattedMessage} from "react-intl";
import React from "react";

export const EXCEPTION_MESSAGES = {
    NOT_FOUND_DB: "User not in DB",
    CONFLICT: "Duplicate user",
    USER_NOT_FOUND: "User not found",
    UNAUTHORIZED: "Invalid password",
    INVALID_REFRESH_TOKEN: "Invalid refresh token",
}
export const APP_DOMAINS = {
    APP_SOSITO_RO: "app.sosito.ro",
    APP_INNENU_SE: "app.innenu.se"
}
export const LOCALSTORAGE_ITEMS = {
    APP_VERSION: "app_version",
    LOCALE: "locale",
    TIMEZONE: "timezone",
    USER_ID: "user_id",
    USERNAME: "username",
    RESET_PASSWORD_PHONE: "reset_password_phone",
    FIRST_NAME: "firstname",
    LAST_NAME: "lastname",
    TYPE: "TYPE",
    ACCESS_TOKEN: "access_token",
    REFRESH_TOKEN: "refresh_token",
    COMPANY_ID: "company_id",
    COMPANY_INFO: "company_info",
    SELECTED_DATE: "selected_date",
    SCHEDULER_ENABLED: "scheduler_enabled",
    LEAVE_TYPES: "leave_types"
}

export const TYPES = {
    SUPER_ADMIN: "SUPER_ADMIN",
    ADMIN: "ADMIN",
    MANAGER: "MANAGER",
    WORKER: "WORKER"
}

export const REPORT_TYPES = {
    HOURLY_REPORT: <FormattedMessage id="HourlyReport"/>,
    CLOCKING_REPORT: <FormattedMessage id="ClockingReport"/>,
    CLOCKING_REPORT_EDITABLE: <FormattedMessage id="ClockingReportEditable"/>
}

export const DAY_MILLIS = 24 * 60 * 60 * 1000;

export const TABS = {
    SCHEDULE_TAB: "SCHEDULE_TAB",
    HOLIDAY_TAB: "HOLIDAY_TAB"
}



export const STATUSES = {
    PENDING: "PENDING",
    APPROVED: "APPROVED",
    DENIED:"DENIED",
    CANCELED:"CANCELED",
}

export const statusMap = {
    "PENDING": "Pending",
    "APPROVED": "Approved",
    "DENIED": "Denied",
    "CANCELED": "Canceled"
};

export const getStatusMessage = (status) => {
    return statusMap[status] ?
        <FormattedMessage id={statusMap[status]} /> : status;
};

export const parseTimePeriod = (timePeriod) => {
    const [from, to] = timePeriod.split(' - ');
    return { from: new Date(from), to: new Date(to) };
};

export const periodsOverlap = (selectedPeriod, leavePeriod) => {
    return selectedPeriod.from >= leavePeriod.from && selectedPeriod.from <= leavePeriod.to || selectedPeriod.to >= leavePeriod.from && selectedPeriod.to <= leavePeriod.to && leavePeriod.from <= selectedPeriod.to;
};

export const colors = [
    "#FF9999",
    "#66CC66",
    "#6666FF",
    "#FFCC80",
    "#66B3FF",
    "#FF8C33",
    "#E1A9FF",
];


