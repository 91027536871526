import React, {useEffect, useState, useContext} from "react";
import {FormattedDate, FormattedMessage} from "react-intl";
import {useParams} from "react-router-dom";
import 'react-calendar/dist/Calendar.css';
import Header from "../../components/Header";
import Spinner from "../../components/Spinner";
import RightPanel from "../../components/RightPanel";

import VacationRequestDetails from "./VacationRequestDetails";


import {
    getAllLocationsForCompanyHandler
} from "../../handlers/companiesHandlers/employeesHandlers/HandlerEmployeesDetails";

import { useIntl } from 'react-intl';
import Table from "../../components/Table";
import Select from "react-select";
import * as StorageServices from "../../services/StorageServices";
import {

    LOCALSTORAGE_ITEMS,
    STATUSES, statusMap,
    TYPES,
    TYPES as TYPE
} from "../../util/Constants";
import {getUserAgent, selectStyles} from "../../util/common";
import {adminIcon} from "../../components/Icons";
import {LocaleContext} from "../../util/LocaleContext";
import * as StorageService from "../../services/StorageServices";



import TabNavigation from "../../components/TabNavigation";
import {
    getAllEmployeeLeavesByCompanyIdHandler, getAllEmployeesByCompanyIdHandler
} from "../../handlers/vacationPlannerHandlers/HandlerVacationRequestsAll";

import {getCompanyByIdHandler} from "../../handlers/companiesHandlers/HandlerCompaniesSettings";
import TableWithMultipleSelect from "../../components/TableWithMultipleSelect";
import {PendingLeavesContext} from "../../util/PendingLeavesContext";
import analyticsLogEvent from "../../services/FirebaseService";


function VacationRequestsAll(props) {
    const {companyId} = useParams();
    const allLeaveTypesFromStorage = StorageServices.getItem(LOCALSTORAGE_ITEMS.LEAVE_TYPES) ? JSON.parse(StorageServices.getItem(LOCALSTORAGE_ITEMS.LEAVE_TYPES)) : null;
    const type = StorageServices.getItem(LOCALSTORAGE_ITEMS.TYPE);
    const openedRightPanelStyleName = "open";
    const closedRightPanelStyleName = "closed";
    const pendingLeaveContext = useContext(PendingLeavesContext);
    const intl = useIntl();
    const locale = StorageServices.getItem(LOCALSTORAGE_ITEMS.LOCALE);
    const localeContext = useContext(LocaleContext);
    const [state, setState] = useState({
        loading: true,
        name: "",
        employeesLeaves: [],
        leaveId: null,
        rightPanelStyleName: closedRightPanelStyleName,
        selectedEmployee: null,
        allLocations: null,
        allRoles: null,
        selectedRole: {
            label: <FormattedMessage id="All"/>,
            value: null
        },
        selectedLocation: {
            label: <FormattedMessage id="All" defaultMessage="All" />,
            value: null
        },
        selectedStatus: {
            label: <FormattedMessage id="All" defaultMessage="All" />,
            value: null
        }
    });

    useEffect(() => {
        analyticsLogEvent("page_view", {
            page_title: "VacationCalendarAll"
        });
        getPageContent();
    }, []);



    const columns = [
        {
            Header: <FormattedMessage id="Id" defaultMessage="Id" />,
            accessor: "leaveId",
            isSortable: true,
            hasIcon: true
        },
        {
            Header: <FormattedMessage id="Name" defaultMessage="Name" />,
            accessor: "name",
            isSortable: true,
            sortType: (rowA, rowB, id, desc) => {
                const rv1 = rowA.original[id];
                const rv2 = rowB.original[id];
                const loc1 = typeof(rv1) !== "undefined"?rv1.toLowerCase():"";
                const loc2 = typeof(rv2) !== "undefined"?rv2.toLowerCase():"";
                if (loc1 > loc2) return 1;
                if (loc2 > loc1) return -1;
                return 0;
            },
            hasIcon: true
        },
        {
            Header: <FormattedMessage id="Type" defaultMessage="Type" />,
            accessor: "type",
            isSortable: true,
        },

        {
            Header: <FormattedMessage id="Roles" defaultMessage="Roles" />,
            accessor: "roles",
            isSortable: true,
        },
        {
            Header: <FormattedMessage id="Locations" defaultMessage="Locations" />,
            accessor: "clockingLocations",
            isSortable: true,
        },

        {
            Header: <FormattedMessage id="Requests" defaultMessage="Requests" />,
            accessor: "leaveType",
            isSortable: false,
        },
        {
            Header: <FormattedMessage id="DateSubmitted" defaultMessage="DateSubmitted" />,
            accessor: "leaveCreated",
            isSortable: true,
            Cell: (row) => <div>{
                <FormattedDate
                    value={row.value}
                    day="numeric"
                    month="short"
                    year="numeric"
                    timeZone={StorageService.getItem(LOCALSTORAGE_ITEMS.TIMEZONE)}
                    locale={StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE)}
                />

            }</div>,
        },
        {
            Header: <FormattedMessage id="TimePeriod" defaultMessage="TimePeriod" />,
            accessor: "leaveTimePeriod",
            isSortable: false,
        },

        {
            Header: <FormattedMessage id="Status" defaultMessage="Status" />,
            accessor: "leaveStatus",
            isSortable: true,

            Cell: (row) => (
                <div
                    className={
                        row.value === intl.formatMessage({ id: statusMap[STATUSES["APPROVED"]] })
                            ? "success"
                            : row.value === intl.formatMessage({ id: statusMap[STATUSES["PENDING"]] })
                                ? "warning"
                                : row.value === intl.formatMessage({ id: statusMap[STATUSES["CANCELED"]] })
                                    ? "canceled"
                                    : "danger"
                    }
                >
                    {row.value}
                </div>
            )
        }
    ];



    const getEmployeesLeaves = (allEmployees, allLeaves, allLocations, allRoles, locale, allLeaveTypesFromStorage) => {
        const locationsMap = {};
        const rolesMap = {};
        allLocations.forEach(location => {
            locationsMap[location.id] = location.name;
        });

        allRoles.forEach(role => {
            rolesMap[role.id] = role.name;
        });


        const leaveTypeMapping = allLeaveTypesFromStorage.reduce((acc, leaveType) => {
            acc[leaveType.id] = leaveType[locale] || leaveType.en;
            return acc;
        }, {});



        const employeesWithMappedLeaveTypes = allLeaves.map(leave => ({
            ...leave,
            leaveType: leaveTypeMapping[leave.leaveType] || leave.leaveType,
        }));

        return employeesWithMappedLeaveTypes.map(leave => {
            const employee = allEmployees.find(emp => emp.id === leave.id);

            const eClockingLocationsNames = employee?.clockingLocations?.map(l => locationsMap[l]).join(", ");
            const eRolesNames = employee?.roles?.filter(r => rolesMap[r])?.map(r => rolesMap[r]).join(", ");
            const eLeaveStatus = leave.leaveStatus

            return {
                ...leave,
                roles: eRolesNames,
                clockingLocations: eClockingLocationsNames,
                leaveStatus:intl.formatMessage({ id: statusMap[STATUSES[eLeaveStatus]] })
            };
        });
    };

    const getPageContent = () => {
        let companyName;
        if (type === TYPES.SUPER_ADMIN) {
            getCompanyByIdHandler(companyId).then(r=>{
                const companyDetailsResponse = r.data.company.name
                companyName = companyDetailsResponse;
            });
        }
        getAllEmployeesByCompanyIdHandler(companyId).then(allEmployeesResponse => {
            getAllEmployeeLeavesByCompanyIdHandler(companyId).then(allLeavesResponse => {
                getAllLocationsForCompanyHandler(companyId).then(locationsResponse => {


                    const allEmployees = allEmployeesResponse.data.allCompanyAndEmployees.employees;
                    const allLeaves = allLeavesResponse.data.allCompanyAndEmployees.employees;
                    const allLocations = locationsResponse.data.locations;
                    const allRoles = allEmployeesResponse.data.allCompanyAndEmployees.allRoles;


                    const employeesLeaves = getEmployeesLeaves(
                        allEmployees,
                        allLeaves,
                        allLocations,
                        allRoles,
                        locale,
                        allLeaveTypesFromStorage,

                    );

                    const callback = () => {
                        setState({
                            ...state,
                            loading: false,
                            name: companyName,
                            employeesLeaves: employeesLeaves,
                            allLocations: locationsResponse.data.locations,
                            allRoles: allEmployeesResponse.data.allCompanyAndEmployees.allRoles,
                            rightPanelStyleName: closedRightPanelStyleName,
                            leaveId: null,
                        });
                    }

                    if (type !== TYPES.SUPER_ADMIN) {
                        localeContext.setLocale(StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE), callback);
                    }else{
                        callback();
                    }
                });
            });
        });
    };

    const closeRightPanel = (isSaveBtnClicked) => {
        if (isSaveBtnClicked) {
            getPageContent();
        } else {
            setState({
                ...state,
                rightPanelStyleName: closedRightPanelStyleName,
                leaveId: null,
            });
        }
    }

    const getAvailableLocations = () => {
        const locs = state.allLocations.map(location => {
            return {
                label: location.name,
                value: location.id
            }
        });
        locs.unshift({
            label: <FormattedMessage id="All" defaultMessage="All" />,
            value: null
        });
        return locs;
    }
    const getAvailableRoles = () => {
        const roles = state.allRoles.map(role => {
            return {
                label: role.name,
                value: role.id
            }
        });
        roles.unshift({
            label: <FormattedMessage id="All"/>,
            value: null
        });
        return roles;
    }

    const getAllAvailableStatuses = () => {
        const locs = Object.keys(STATUSES).map(key => {
            return {
                label: intl.formatMessage({ id: statusMap[STATUSES[key]] }),
                value: key
            }
        });
        locs.unshift({
            label: <FormattedMessage id="All" defaultMessage="All"/>,
            value: null
        });
        return locs;
    };

    const openRightPanel = (leaveId, employee) => {

        if (leaveId) {
            setState({
                ...state,
                leaveId: leaveId,
                selectedEmployee: employee,
                rightPanelStyleName: openedRightPanelStyleName,

            });
        } else {
            setState({
                ...state,
                leaveId: null,
                rightPanelStyleName: openedRightPanelStyleName,
            });
        }
    }

    const onSelectedRowId = (rowId) => {
        openRightPanel(state.employeesLeaves[rowId].leaveId, state.employeesLeaves[rowId]);
    };

    const handleOnChange = (e, input) => {
        if (input === "location") {
            setState({
                ...state,
                errorMsg: null,
                selectedLocation: {
                    label: e.label,
                    value: e.value
                }
            })

        } else if (input === "status") {
            setState({
                ...state,
                errorMsg: null,
                selectedStatus: {
                    label: e.label,
                    value: e.value
                }
            })
        }else if (input === "role") {
            setState({
                ...state,
                selectedRole: {
                    label: e.label,
                    value: e.value
                }
            });
        }
    }


    return state.loading ?
        <Spinner />
        : (
            <div className="content">
                <Header
                    companyId={companyId}
                    hasProfileBtn={true}
                    hasBackBtn={type !== TYPES.MANAGER && type !== TYPES.ADMIN}
                    headerTitle={type === TYPES.SUPER_ADMIN ? state.name : <FormattedMessage id="VacationRequests" defaultMessage="VacationRequests" />}

                />
                <div className="inner-content inner-content-for-table">
                    {props.hasTabNavigation &&
                        <TabNavigation
                            companyId={companyId}
                            activeTab={type === TYPE.SUPER_ADMIN ? 7 : 0}
                            page="vacation-planner"
                            pendingLeavesCount={pendingLeaveContext.pendingLeavesCount}

                        />
                    }
                    {state.employeesLeaves ?
                        <TableWithMultipleSelect
                            topActions={
                                <section className="top-actions">
                                    <div className="form-group select-form-group">
                                        <div className="row">
                                            <label className="label">
                                                <FormattedMessage id="Location" />
                                            </label>
                                        </div>
                                        <div className="row">
                                            <FormattedMessage id="All" defaultMessage="All">
                                                {placeholder =>
                                                    <Select
                                                        className="select"
                                                        options={getAvailableLocations()}
                                                        onChange={e => handleOnChange(e, "location")}
                                                        defaultValue={state?.selectedLocation}
                                                        placeholder={state?.selectedLocation ? state?.selectedLocation.label : placeholder}
                                                        noOptionsMessage={() => <FormattedMessage id="NoOptionsAvailable" />}
                                                        id="location"
                                                        styles={selectStyles}
                                                        components={{
                                                            IndicatorSeparator: () => null
                                                        }}
                                                    />
                                                }
                                            </FormattedMessage>
                                        </div>
                                    </div>

                                    <div className="form-group select-form-group">
                                        <div className="row">
                                            <label className="label">
                                                <FormattedMessage id="Roles" />
                                            </label>
                                        </div>
                                        <div className="row">
                                            <FormattedMessage id="Roles" defaultMessage="Roles">
                                                {placeholder =>
                                                    <Select
                                                        className="select"
                                                        options={getAvailableRoles()}
                                                        onChange={e => handleOnChange(e, "role")}
                                                        defaultValue={state?.selectedRole}
                                                        placeholder={state?.selectedRole ? state?.selectedRole.label : placeholder}
                                                        noOptionsMessage={() => <FormattedMessage id="NoOptionsAvailable" />}
                                                        id="status"
                                                        styles={selectStyles}
                                                        components={{
                                                            IndicatorSeparator: () => null
                                                        }}
                                                    />
                                                }
                                            </FormattedMessage>
                                        </div>
                                    </div>


                                    <div className="form-group select-form-group">
                                        <div className="row">
                                            <label className="label">
                                                <FormattedMessage id="Status" />
                                            </label>
                                        </div>
                                        <div className="row">
                                            <FormattedMessage id="All" defaultMessage="All">
                                                {placeholder =>
                                                    <Select
                                                        className="select"
                                                        options={getAllAvailableStatuses()}
                                                        onChange={e => handleOnChange(e, "status")}
                                                        defaultValue={state?.selectedStatus}
                                                        placeholder={state?.selectedStatus ? state?.selectedStatus.label : placeholder}
                                                        noOptionsMessage={() => <FormattedMessage id="NoOptionsAvailable" />}
                                                        id="status"
                                                        styles={selectStyles}
                                                        components={{
                                                            IndicatorSeparator: () => null
                                                        }}
                                                    />
                                                }
                                            </FormattedMessage>
                                        </div>
                                    </div>
                                </section>
                            }
                            columns={columns}
                            data={state.employeesLeaves}
                            onSelectedRowId={onSelectedRowId}
                            selectedItemId={state.leaveId}
                            searchBoxLabel={<FormattedMessage id="SearchEmployee" defaultMessage="SearchEmployee" />}
                            filters={[
                                {
                                    accessor: "clockingLocations",
                                    value: state.selectedLocation ? state.selectedLocation : null,
                                    function: (tv, v) => tv && tv.indexOf(v) >= 0,
                                },
                                {
                                    accessor: "roles",
                                    value: state.selectedRole ? state.selectedRole : null,
                                    function: (tv, v) => tv && tv.indexOf(v) >= 0,
                                },
                                {
                                    accessor: "leaveStatus",
                                    value: state.selectedStatus ? state.selectedStatus : null,
                                    function: (tv, v) => tv === v,
                                },
                            ]}
                            icon={adminIcon}
                            closeRightPanel={closeRightPanel}
                            extraClassName={"employees-table"}
                        />
                        :
                        <div className="no-data-yet-container"> <FormattedMessage id="NoEmployeesYet" defaultMessage="NoEmployeesYet" /></div>
                    }
                </div>
                <RightPanel
                    isOpened={state.rightPanelStyleName}
                    closeRightPanel={closeRightPanel}
                    title={<FormattedMessage id="VacationDetails" defaultMessage="VacationRequestDetails" />}
                    accountDetailsId={state.leaveId ? state.leaveId : null}
                    children={
                        state.leaveId !== null ?
                            <VacationRequestDetails
                                companyId={companyId}
                                leaveId={state.leaveId ? state.leaveId : null}
                                employeesLeaves={state.selectedEmployee}
                                closeRightPanel={closeRightPanel}
                            /> : null
                    }
                />
            </div>
        );
}

export default VacationRequestsAll;
