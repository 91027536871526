
import {
    createCompanyWorkTimeIntervals, deleteCompanyWorkTimeIntervalsById, editCompanyWorkTimeIntervalsById,
    getAllCompanyWorkTimeIntervalsFromCompany
} from "../../services/schedulesService/SchedulesService";
import {getHourAndMinFromMillis, getMillis} from "../../util/common";
import {getCompanyById} from "../../services/companiesServices/CompaniesService";
import {colors} from "../../util/Constants";



export const getAllCompanyWorkTimeIntervalsHandler = async (companyId) => {
    let error = null;
    const companyDetailsResponse = await getCompanyById(companyId);
    const response = await getAllCompanyWorkTimeIntervalsFromCompany(companyId);
    let schedulesResponse = null;
    if (response.ok) {
        schedulesResponse = response.data.map(schedule=>{

            let sheduleId = parseInt(schedule.id);
            let colorIndex = sheduleId % colors.length;


            return  {
                companyId: schedule.companyId,
                id: schedule.id,
                name: schedule.name,
                color: colors[colorIndex],
                daysIntervals: schedule.daysIntervals.map(day => {
                    return {
                        dayIntervals: day.dayIntervals.map(dayInterval => {
                            return {
                                from: getHourAndMinFromMillis(dayInterval.from),
                                to: getHourAndMinFromMillis(dayInterval.to)
                            };
                        })
                    }
                })
            }
        }).sort((a, b) => parseInt(a.id) - parseInt(b.id)); //sort by id

    } else {
        error ="Error getAllEmployeesByFacilityIdHandler";
    }

    return {
        data: {
            error: error,
            schedules: schedulesResponse,
            companyName: companyDetailsResponse.data.name,
        }
    };
}


export const createCompanyWorkTimeIntervalsHandler = async (data) => {
    const body = {
        "companyId": data.companyId,
        "name": data.name,
        "daysIntervals": data.daysIntervals.map(day => {
        return {
            dayIntervals: day.dayIntervals.map(dayInterval => {
                return {
                    from: getMillis(dayInterval.from),
                    to: getMillis(dayInterval.to)
                };
            })
        }
    })
    }
    const response = await createCompanyWorkTimeIntervals(body);
    let scheduleId = null;
    let error = null;
    if (response.ok) {
        scheduleId = response.data.id
    } else {
        error =response.data.message;
    }

    return {
        data: {
            error: error,
            id: scheduleId
        }
    };
}


export const editCompanyWorkTimeIntervalsHandler = async (scheduleId, data) => {
    let error = null;
    const body = {
        "name": data.name,
        "daysIntervals": data.daysIntervals.map(day => {
            return {
                dayIntervals: day.dayIntervals.map(dayInterval => {
                    return {
                        from: getMillis(dayInterval.from),
                        to: getMillis(dayInterval.to)
                    };
                })
            }
        })
    }

    const response = await editCompanyWorkTimeIntervalsById(scheduleId, body);
    if (response.ok) {
    } else {
        error = response.data.message;
    }
    return {
        data: {
            error: error
        }
    };
}


export const deleteCompanyWorkTimeIntervalsHandler = async (scheduleId) => {
    const response = await deleteCompanyWorkTimeIntervalsById(scheduleId);
    let error = null;
    if (response.ok) {

    } else {
        error ="Error deleteCompanyWorkTimeIntervals";
    }

    return {
        data: {
            error: null
        }
    };
}



