import React, {useEffect, useState, useRef, useContext} from "react";
import {FormattedMessage} from "react-intl";
import 'react-calendar/dist/Calendar.css';
import {
    getDayName, getHourAndMinFromMillis, getTotalHoursFromIntervalMillis, getWeekends, roundToHoursAndMinutes,
} from "../../util/common";

import {
    adminIcon,
    alertIcon,
    closeIcon,
    closeIconRoundedRed,
    downArrowIcon,
    middotIcon,
    holidayIcon,
    scheduleIcon, historyIcon, trashIcon, sortDownIcon, sortUpIcon
} from "../../components/Icons";
import * as StorageService from "../../services/StorageServices";
import {colors, LOCALSTORAGE_ITEMS, TABS, TYPES} from "../../util/Constants";
import analyticsLogEvent from "../../services/FirebaseService";


function WorkHoursTable(props) {

    const [state, setState] = useState({
        allEmployees:[],
        selectedIndex: null,
        sortingState: null
    });
    useEffect(() => {
        setState({
            ...state,
            allEmployees: props.allEmployees,
        })
    }, [props.allEmployees]);

    const scheduleIdToIndexMap = {};
    props.allSchedules.forEach((s, i) => {
        scheduleIdToIndexMap[s.id] = {
            index: i,
            name: s.name
        };
    });

    let allEmployeeIds = [];
    state.allEmployees.forEach(employee => {
        if (props.employeeCanBeEdited(employee)) {
            allEmployeeIds.push(employee.id)
        }
    });
    let allEmployeeIdsWithoutDuplicates = [...new Set(allEmployeeIds)];

    const getComparisonObjectForEmployee = (index, e, allDateWorkTimeIntervals) => {

        let from = -2;

        if(typeof e.workSchedule === "undefined" || e.workSchedule.length === 0){
            from = -2;
        }else {
            const dayIntervals =  e.workSchedule[index].dayIntervals || null;

            if(dayIntervals === null){
                from = -2;
            }else if(dayIntervals.length === 0){
                from = -1;
            }else {
                from = dayIntervals[0].from;
            }
        }

        return {
            id: e.id,
            name: e.name,
            from: from
        };
    };


    const compareByStartTimeAndNameAndId = (o1, o2) =>{
        // object format
        //{
        // from:< -2 | -1 | number> // -2 = null/empty intervals, -1 = none,
        // name: "<employee name>",
        // id: "<employee id>"
        //}
        if(o1.from < o2.from) return -1;
        else if(o1.from > o2.from) return 1;
        else if(o1.from === o2.from) {
            const nameComp = o1.name.localeCompare(o2.name);
            if(nameComp === 0){
                return o1.id.localeCompare(o2.id);
            }else return nameComp;
        }
    };



    const compareByNameAndId = (o1, o2) =>{
        // object format
        //{
        // name: "<employee name>",
        // id: "<employee id>"
        //}
        const nameComp = o1.name.localeCompare(o2.name);
        if(nameComp === 0){
            return o1.id.localeCompare(o2.id);
        }else return nameComp;
    };



    const handleSortByWorkSchedule = (index) => {
        let ascSort = null;
        if(state.sortingState === null){
            ascSort = true;
        }else if(state.sortingState == true){
            ascSort = false;
        }else {
            ascSort = null;
        }
        if(ascSort === null){
            setState((prevState) => ({
                ...prevState,
                allEmployees: props.allEmployees,
                sortingState: null,
                selectedIndex:null
            }));
            return;
        }

        const sortedEmployees = [...state.allEmployees].sort((a, b) => {

            const o1 = getComparisonObjectForEmployee(index, a);
            const o2 = getComparisonObjectForEmployee(index, b);


            if (ascSort) {
                return compareByStartTimeAndNameAndId(o1, o2);
            }else{
                return - compareByStartTimeAndNameAndId(o1, o2);
            }
        });


        setState((prevState) => ({
            ...prevState,
            allEmployees: sortedEmployees,
            sortingState: ascSort,
            selectedIndex: index
        }));
    };

    const getDayWorkTimeFrom = (dayWork) => {
        if (dayWork.dayIntervals) {
            if (dayWork.dayIntervals.length > 0) {
                return getHourAndMinFromMillis(dayWork.dayIntervals[0].from);
            } else {
                return "none";
            }
        } else return "";
    }


    const getDayWorkTimeTo = (dayWork) => {
        if (dayWork.dayIntervals) {
            if (dayWork.dayIntervals.length > 0) {
                return getHourAndMinFromMillis(dayWork.dayIntervals[0].to);
            } else {
                return "none";
            }
        } else return "";
    }

    const onDeleteScheduleForSelectedDay = (e) => {
        e.stopPropagation();
        props.deleteWorkForSelectedDay();
    }


    const onDeleteInterval = (e, id) => {
        e.stopPropagation();
        props.onDeleteInterval(id);
    }

    const onTabClick = (e, tab) => {
        e.stopPropagation();
        props.changeActiveTab(tab);
    }


    const groupedEmployees = {};
    state.allEmployees.forEach((employee) => {
        const locations = employee.clockingLocations || ["Unknown Location"];
        locations.forEach((location) => {
            if (!groupedEmployees[location]) {
                groupedEmployees[location] = [];
            }
            groupedEmployees[location].push(employee);
        });
    });


    return (
        <>
            {props.assignWorkPatternPopupVisible &&
                <div
                    style={{
                        left: props.assignWorkPatternPopupPosition.left + "px",
                        top: props.assignWorkPatternPopupPosition.top + "px"
                    }}
                    className="popup list-popup"
                    onContextMenu={(e) => e.preventDefault()}>
                    <div className="close-list-popup"
                         onContextMenu={(e) => e.preventDefault()}
                         onClick={(e) => props.closeWorkPatternPopup(e)}>
                         <span className="close-icon">
                             {closeIcon}
                         </span>
                    </div>

                    {props.activeTab === TABS.SCHEDULE_TAB && <>
                        <div onClick={(e) => {
                            e.stopPropagation();
                            props.openDeletePatternAlert()
                        }} className="row reset-pattern">&nbsp;<FormattedMessage id="ResetSchedule"/></div>

                        <div className="options">
                            {props.allSchedules.map((schedule, index) => (
                                <div
                                    style={{
                                        borderRight: "10px solid " + (
                                            scheduleIdToIndexMap[schedule.id]
                                                ? colors[scheduleIdToIndexMap[schedule.id].index % colors.length]
                                                : "#b8b8b8"
                                        )
                                    }}
                                    key={index}
                                    onClick={() => props.openScheduleCalendar(schedule.id)}
                                    className={(schedule.id === props.selectedSchedule) || (schedule.id === props.activeScheduleForEmployee?.id) ? "row active" : "row"}>{schedule.name}</div>
                            ))}
                        </div>
                    </>}


                    <div className="invisible-close-list-popup-overlay"
                         onClick={(e) => props.closeWorkPatternPopup(e)}
                         onContextMenu={(e) => props.closeWorkPatternPopup(e)}>

                    </div>
                </div>}
            <table className="table reports-table work-hours-table editable">

                {/*---------------------------------start thead------------------------*/}
                <thead>
                <tr>
                    <th onContextMenu={(e) => e.preventDefault()}
                        onClick={(e) => props.onSelectAllRowsIds(e, allEmployeeIdsWithoutDuplicates)}
                        className="clickable name">
                        <div className="row">
                            <div className="checkbox">
                                <input type="checkbox" onChange={(e) => (e.stopPropagation())}
                                       checked={JSON.stringify(props.selectedRowIds) === JSON.stringify(allEmployeeIdsWithoutDuplicates) && state.allEmployees.length > 0 && allEmployeeIdsWithoutDuplicates.length > 0}/>
                                <div className="checksquare"></div>
                            </div>
                            <FormattedMessage id="SelectAll"/>

                        </div>

                    </th>
                    {
                        Array.from(Array(props.numOfDaysInMonth)).map((day, index) => (
                            <th className="sortable" onClick={() => handleSortByWorkSchedule(index)} key={index}>{index + 1} {getDayName(props.selectedDate, index + 1, StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE))}
                                {state.selectedIndex === index && <span className={`sorting-icon-cont`}>
                                    {state.sortingState === true
                                        ? sortUpIcon
                                        : state.sortingState === false
                                            ? sortDownIcon
                                            :null}
                                </span>}
                                {state.selectedIndex !== index && <span className={`sorting-icon-cont`}>{sortUpIcon}{sortDownIcon}</span>}
                            </th>
                        ))
                    }
                </tr>
                </thead>
                {/*---------------------------------end thead------------------------*/}


                {/*---------------------------------start tbody------------------------*/}
                <tbody>
                {(state.allEmployees.length > 0 && !props.groupedByLocation) && state.allEmployees.map((employee, employeeIndex) => (
                    <tr
                        className={props.selectedRowIds.includes(employee.id) ? "all-active " + employeeIndex : employeeIndex}
                        key={employeeIndex}>
                        <td

                            onContextMenu={(e) => props.openWorkPatternPopup(e, employee)}
                            onClick={() => props.onSelectSingleRowId(employee)}
                            className={
                                props.employeeCanBeEdited(employee) ?
                                    "clickable name"
                                    : "non-clickable name"
                            }>
                            <div className="row">
                                {props.listOfEditedEmployees.includes(employee.id) && <i className="middot-icon">
                                    {middotIcon}
                                </i>}
                                {props.employeeCanBeEdited(employee) ?
                                    <div className="checkbox">
                                        <input type="checkbox" onChange={(e) => (e.stopPropagation())}
                                               checked={props.selectedRowIds.includes(employee.id)}/>
                                        <div className="checksquare"></div>
                                    </div>
                                    :
                                    null
                                }

                                {employee.type === TYPES.ADMIN || employee.type === TYPES.MANAGER ? adminIcon : null}
                                {employee.name}
                                <i onClick={(e) => {
                                    e.stopPropagation();
                                    props.openWorkPatternPopup(e, employee)
                                }} className="icon">{downArrowIcon}</i>
                                {employee?.workScheduleDetails?.id !== undefined && <div
                                    style={{
                                        borderRight: "10px solid " +  (scheduleIdToIndexMap[employee.workScheduleDetails.id]?colors[scheduleIdToIndexMap[employee.workScheduleDetails.id].index % colors.length]: "#b8b8b8")
                                    }}
                                    className="schedule-bar"
                                >
                                    <div className="tooltip">{employee.workScheduleDetails.name === scheduleIdToIndexMap[employee.workScheduleDetails.id]?.name?
                                        employee.workScheduleDetails.name
                                        :(employee.workScheduleDetails.name + (scheduleIdToIndexMap[employee.workScheduleDetails.id]?
                                            (" ("+ scheduleIdToIndexMap[employee.workScheduleDetails.id].name+")")
                                            :" (Deleted)"))}</div>
                                </div>}
                            </div>
                        </td>
                        {
                            employee.workSchedule.map((dayWork, index) => (
                                <td style={{
                                    // backgroundColor: colors[scheduleIdToIndexMap[employee.workScheduleDetails.id] % colors.length],
                                }}className={(props.weekendDaysInMonth.includes(index + 1) ? "weekend" : "") + (dayWork.isEdited ? " edited" : "") + (props.employeeCanBeEdited(employee, true) ? "" : " non-clickable")}
                                    tabIndex={props.getIndex(employeeIndex, index) + 1} key={index}
                                    onKeyUp={(e) => props.showEditableInputsForSelectedDay(employee, index, false, e)}
                                    onClick={(e) => props.showEditableInputsForSelectedDay(employee, index, false, e)}
                                >

                                    <span className="from">
                                        {
                                            getDayWorkTimeFrom(dayWork)
                                        }
                                    </span>&nbsp;-&nbsp;
                                    <span className="to">
                                        {
                                            getDayWorkTimeTo(dayWork)
                                        }
                                    </span>


                                    {/* START EDITABLE INPUTS*/}
                                    {
                                        props.selectedEmployee && props.selectedEmployee == employee.id && props.selectedDay === index &&
                                        <div
                                            className={props.hasErrors ? "row has-errors editable-time prevent-scroll" : "row editable-time prevent-scroll"}>
                                            {/*<span className="copy-value"></span>*/}
                                            <span className="from row">
                                                 <input
                                                     placeholder={
                                                         dayWork.dayIntervals && dayWork.dayIntervals.length > 0? roundToHoursAndMinutes(dayWork.dayIntervals[0].from).h : ""
                                                     }
                                                     autoFocus
                                                     tabIndex={props.getIndex(employeeIndex, index) + 1}
                                                     onChange={(e) => props.saveIntervalsInState(e, "hoursFrom")}
                                                     onKeyDown={(e) => props.handleKeyDown(e, "hoursFrom")}
                                                     type="number"
                                                     className="hours"/>
                                            <span className="middot">:</span>
                                            <input
                                                placeholder={dayWork.dayIntervals && dayWork.dayIntervals.length > 0? roundToHoursAndMinutes(dayWork.dayIntervals[0].from).m : ""}
                                                tabIndex={props.getIndex(employeeIndex, index) + 1}
                                                onChange={(e) => props.saveIntervalsInState(e, "minutesFrom")}
                                                onKeyDown={(e) => props.handleKeyDown(e, "minutesFrom")}
                                                type="number"
                                                className="minutes"/>
                                            </span>


                                            <span className="dash">-</span>
                                            <span className="to row">
                                                <input
                                                    placeholder={dayWork.dayIntervals && dayWork.dayIntervals.length > 0? roundToHoursAndMinutes(dayWork.dayIntervals[0].to).h : ""}
                                                    tabIndex={props.getIndex(employeeIndex, index) + 1}
                                                    onChange={(e) => props.saveIntervalsInState(e, "hoursTo")}
                                                    onKeyDown={(e) => props.handleKeyDown(e, "hoursTo")}
                                                    type="number"
                                                    className="hours"/>
                                            <span className="middot">:</span>
                                            <input
                                                placeholder={dayWork.dayIntervals && dayWork.dayIntervals.length > 0? roundToHoursAndMinutes(dayWork.dayIntervals[0].to).m : ""}
                                                tabIndex={props.getIndex(employeeIndex, index) + 1}
                                                onChange={(e) => props.saveIntervalsInState(e, "minutesTo")}
                                                onKeyDown={(e) => props.handleKeyDown(e, "minutesTo")}
                                                type="number"
                                                className="minutes"/>
                                            </span>

                                            {(getDayWorkTimeFrom(dayWork) !== "" || getDayWorkTimeTo(dayWork) !== "") && <div className="buttons">
                                                {dayWork.isEdited &&
                                                    <div className="button-container" onClick={(e) => {onDeleteInterval(e, dayWork.editedDateId)}}>
                                                        <button
                                                                className="rollback-btn btn btn-default">{historyIcon}
                                                        </button>
                                                        <div className="info"><FormattedMessage id="Rollback"/></div>
                                                    </div>

                                                }
                                                <div className="button-container" onClick={(e) => {onDeleteScheduleForSelectedDay(e)}}>
                                                    <button
                                                            className="delete-btn btn btn-danger">{trashIcon}
                                                    </button>
                                                    <div className="info"><FormattedMessage id="Delete"/></div>
                                                </div>
                                            </div>}


                                            {props.hasErrors && <div className="tooltip cell-tooltip">
                                                <div className="arrow-up"></div>
                                                <div className="row colour-one">
                                                    <div className="col icon-col">{alertIcon}&nbsp;</div>
                                                    <div className="col text-col">
                                                        {props.errorMsg}
                                                    </div>
                                                </div>
                                            </div>}
                                        </div>
                                    }
                                    {/*END EDITABLE INPUTS*/}
                                </td>
                            ))
                        }
                    </tr>
                ))}

                {(props.groupedByLocation) && Object.keys(groupedEmployees).map((locationId) => (
                    <React.Fragment key={locationId}>

                        <tr key={locationId} className="group-header">
                            <td
                            >
                                <h1>{`${
                                    props.allLocations.find((loc) => loc.id === locationId)?.name || "Unknown"
                                }`}</h1>
                            </td>
                        </tr>


                        {groupedEmployees[locationId].map((employee, employeeIndex) => (
                            <tr
                                className={
                                    props.selectedRowIds.includes(employee.id)
                                        ? "all-active " + employeeIndex
                                        : employeeIndex
                                }
                                key={employeeIndex}
                            >
                                <td
                                    onContextMenu={(e) => props.openWorkPatternPopup(e, employee)}
                                    onClick={() => props.onSelectSingleRowId(employee)}
                                    className={
                                        props.employeeCanBeEdited(employee)
                                            ? "clickable name"
                                            : "non-clickable name"
                                    }
                                >
                                    <div className="row">
                                        {props.listOfEditedEmployees.includes(employee.id) && (
                                            <i className="middot-icon">{middotIcon}</i>
                                        )}
                                        {props.employeeCanBeEdited(employee) ? (
                                            <div className="checkbox">
                                                <input
                                                    type="checkbox"
                                                    onChange={(e) => e.stopPropagation()}
                                                    checked={props.selectedRowIds.includes(
                                                        employee.id
                                                    )}
                                                />
                                                <div className="checksquare"></div>
                                            </div>
                                        ) : null}

                                        {employee.type === TYPES.ADMIN || employee.type === TYPES.MANAGER
                                            ? adminIcon
                                            : null}
                                        {employee.name}
                                        <i
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                props.openWorkPatternPopup(e, employee);
                                            }}
                                            className="icon"
                                        >
                                            {downArrowIcon}
                                        </i>
                                        {employee?.workScheduleDetails?.id !== undefined && (
                                            <div
                                                style={{
                                                    borderRight:
                                                        "10px solid " +
                                                        (scheduleIdToIndexMap[
                                                            employee.workScheduleDetails.id
                                                            ]
                                                            ? colors[
                                                            scheduleIdToIndexMap[
                                                                employee.workScheduleDetails.id
                                                                ].index % colors.length
                                                                ]
                                                            : "#b8b8b8"),
                                                }}
                                                className="schedule-bar"
                                            >
                                                <div className="tooltip">
                                                    {employee.workScheduleDetails.name ===
                                                    scheduleIdToIndexMap[
                                                        employee.workScheduleDetails.id
                                                        ]?.name
                                                        ? employee.workScheduleDetails.name
                                                        : employee.workScheduleDetails.name +
                                                        (scheduleIdToIndexMap[
                                                            employee.workScheduleDetails.id
                                                            ]
                                                            ? " (" +
                                                            scheduleIdToIndexMap[
                                                                employee
                                                                    .workScheduleDetails
                                                                    .id
                                                                ].name +
                                                            ")"
                                                            : " (Deleted)")}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </td>
                                {employee.workSchedule.map((dayWork, index) => (
                                    <td
                                        style={{
                                            // backgroundColor: colors[scheduleIdToIndexMap[employee.workScheduleDetails.id] % colors.length],
                                        }}
                                        className={
                                            (props.weekendDaysInMonth.includes(index + 1)
                                                ? "weekend"
                                                : "") +
                                            (dayWork.isEdited ? " edited" : "") +
                                            (props.employeeCanBeEdited(employee, true)
                                                ? ""
                                                : " non-clickable")
                                        }
                                        tabIndex={props.getIndex(employeeIndex, index) + 1}
                                        key={index}
                                        onKeyUp={(e) =>
                                            props.showEditableInputsForSelectedDay(
                                                employee,
                                                index,
                                                false,
                                                e
                                            )
                                        }
                                        onClick={(e) =>
                                            props.showEditableInputsForSelectedDay(
                                                employee,
                                                index,
                                                false,
                                                e
                                            )
                                        }
                                    >
                        <span className="from">
                            {getDayWorkTimeFrom(dayWork)}
                        </span>
                                        &nbsp;-&nbsp;
                                        <span className="to">{getDayWorkTimeTo(dayWork)}</span>
                                        {/* START EDITABLE INPUTS*/}
                                        {
                                            props.selectedEmployee && props.selectedEmployee == employee.id && props.selectedDay === index &&
                                            <div
                                                className={props.hasErrors ? "row has-errors editable-time prevent-scroll" : "row editable-time prevent-scroll"}>
                                                {/*<span className="copy-value"></span>*/}
                                                <span className="from row">
                                                                         <input
                                                                             placeholder={
                                                                                 dayWork.dayIntervals && dayWork.dayIntervals.length > 0 ? roundToHoursAndMinutes(dayWork.dayIntervals[0].from).h : ""
                                                                             }
                                                                             autoFocus
                                                                             tabIndex={props.getIndex(employeeIndex, index) + 1}
                                                                             onChange={(e) => props.saveIntervalsInState(e, "hoursFrom")}
                                                                             onKeyDown={(e) => props.handleKeyDown(e, "hoursFrom")}
                                                                             type="number"
                                                                             className="hours"/>
                                                                    <span className="middot">:</span>
                                                                    <input
                                                                        placeholder={dayWork.dayIntervals && dayWork.dayIntervals.length > 0 ? roundToHoursAndMinutes(dayWork.dayIntervals[0].from).m : ""}
                                                                        tabIndex={props.getIndex(employeeIndex, index) + 1}
                                                                        onChange={(e) => props.saveIntervalsInState(e, "minutesFrom")}
                                                                        onKeyDown={(e) => props.handleKeyDown(e, "minutesFrom")}
                                                                        type="number"
                                                                        className="minutes"/>
                                                                    </span>


                                                <span className="dash">-</span>
                                                <span className="to row">
                                                                        <input
                                                                            placeholder={dayWork.dayIntervals && dayWork.dayIntervals.length > 0 ? roundToHoursAndMinutes(dayWork.dayIntervals[0].to).h : ""}
                                                                            tabIndex={props.getIndex(employeeIndex, index) + 1}
                                                                            onChange={(e) => props.saveIntervalsInState(e, "hoursTo")}
                                                                            onKeyDown={(e) => props.handleKeyDown(e, "hoursTo")}
                                                                            type="number"
                                                                            className="hours"/>
                                                                    <span className="middot">:</span>
                                                                    <input
                                                                        placeholder={dayWork.dayIntervals && dayWork.dayIntervals.length > 0 ? roundToHoursAndMinutes(dayWork.dayIntervals[0].to).m : ""}
                                                                        tabIndex={props.getIndex(employeeIndex, index) + 1}
                                                                        onChange={(e) => props.saveIntervalsInState(e, "minutesTo")}
                                                                        onKeyDown={(e) => props.handleKeyDown(e, "minutesTo")}
                                                                        type="number"
                                                                        className="minutes"/>
                                                                    </span>

                                                {(getDayWorkTimeFrom(dayWork) !== "" || getDayWorkTimeTo(dayWork) !== "") &&
                                                    <div className="buttons">
                                                        {dayWork.isEdited &&
                                                            <div className="button-container" onClick={(e) => {
                                                                onDeleteInterval(e, dayWork.editedDateId)
                                                            }}>
                                                                <button
                                                                    className="rollback-btn btn btn-default">{historyIcon}
                                                                </button>
                                                                <div className="info"><FormattedMessage id="Rollback"/>
                                                                </div>
                                                            </div>

                                                        }
                                                        <div className="button-container" onClick={(e) => {
                                                            onDeleteScheduleForSelectedDay(e)
                                                        }}>
                                                            <button
                                                                className="delete-btn btn btn-danger">{trashIcon}
                                                            </button>
                                                            <div className="info"><FormattedMessage id="Delete"/></div>
                                                        </div>
                                                    </div>}


                                                {props.hasErrors && <div className="tooltip cell-tooltip">
                                                    <div className="arrow-up"></div>
                                                    <div className="row colour-one">
                                                        <div className="col icon-col">{alertIcon}&nbsp;</div>
                                                        <div className="col text-col">
                                                            {props.errorMsg}
                                                        </div>
                                                    </div>
                                                </div>}
                                            </div>
                                        }
                                        {/*END EDITABLE INPUTS*/}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </React.Fragment>
                ))}
                </tbody>
                {/*---------------------------------end tbody------------------------*/}
            </table>
        </>

    );
}

export default WorkHoursTable;
