import React, {useEffect, useState, useRef, useContext} from "react";
import {FormattedDate, FormattedMessage} from "react-intl";
import {useParams} from "react-router-dom";
import 'react-calendar/dist/Calendar.css';
import Header from "../../components/Header";
import Spinner from "../../components/Spinner";
import * as StorageServices from "../../services/StorageServices";
import {LOCALSTORAGE_ITEMS, TABS, TYPES, TYPES as TYPE} from "../../util/Constants";
import CalendarTmz from "../../components/CalendarTmz";
import * as StorageService from "../../services/StorageServices";
import {
    getFirstDayOfMonth, getLastDayOfMonth,
    getTotalDaysInMonth, addDateWithFields,
    getWeekends, roundToHoursAndMinutes, selectStyles, getUserAgent
} from "../../util/common";
import ScrollContainer from "react-indiana-drag-scroll";

import WorkHoursTable from "./WorkHoursTable";
import TabNavigation from "../../components/TabNavigation";
import {getAllCompanyWorkTimeIntervalsHandler} from "../../handlers/schedulerHandlers/HandlerSchedulesAll";
import {closeIcon, downArrowIcon, middotIcon} from "../../components/Icons";
import {
    deleteDateWorkTimeIntervalsByIdHandler,
    editCompanyEmployeeByIdHandler, editDateWorkTimeIntervalsHandler,
    getAllEmployeesByCompanyIdHandler,
    getDateWorkTimeIntervalsFromCompanyHandler, updateScheduleNotificationsHandler
} from "../../handlers/schedulerHandlers/HandlerWorkHoursAll";
import {getAllLocationsForCompanyHandler} from "../../handlers/companiesHandlers/employeesHandlers/HandlerEmployeesDetails";
import Select from "react-select";
import {LocaleContext} from "../../util/LocaleContext";
import analyticsLogEvent from "../../services/FirebaseService";


function WorkHoursAll() {

    const {companyId} = useParams();
    const type = StorageServices.getItem(LOCALSTORAGE_ITEMS.TYPE);
    const date = new Date();
    const today = getFirstDayOfMonth(date);
    const localeContext = useContext(LocaleContext);
    const [state, setState] = useState({
        loading: true,
        allClockingsReports: [],
        allEditedClockingsReports: [],

        allSchedules: [],
        allEmployees: [],

        listOfEditedEmployees:[],

        allDateWorkTimeIntervals: [],
        calendarIsVisible: false,

        allLocations: null,
        allRoles: null,
        selectedLocation: {
            label: <FormattedMessage id="All" defaultMessage="All" />,
            value: null
        },
        selectedDate: today,
        confirmationModalVisible: false,
        infoToolTipVisible:false,
        notifyEmployeesPopupVisible:false,
        assignWorkPatternPopupVisible: false,
        assignWorkPatternPopupPosition: null,
        isDeletePatternAlertOpen:false,
        numOfDaysInMonth: 31,
        weekendDaysInMonth: getWeekends(today),
        searchedEmployee: null,
        selectedDay: null,
        selectedEmployee:null,

        scheduleCalendarIsVisible: false,
        selectedSchedule: null,
        selectedWorkHoursPeriodFromCalendar: null,
        activeScheduleForEmployee:null,


        editedClockingReportMinutesFrom: null,
        editedClockingReportHoursFrom: null,
        editedClockingReportMinutesTo: null,
        editedClockingReportHoursTo: null,


        hasErrors: false,
        errorMsg: null,
        selectedRowIds: [],
        activeTab:TABS.SCHEDULE_TAB,
        selectedHolidayType: null,

        sortedEmployees: [],
        groupedByLocation:false
    });

    useEffect(() => {
        analyticsLogEvent("page_view", {
            page_title: "WorkHoursAll"
        });
    }, []);




    useEffect(() => {
        getPageContent();
    }, [state.selectedDate]);


    useEffect(() => {
        setState({
            ...state,
            numOfDaysInMonth: getTotalDaysInMonth(state.selectedDate),
            weekendDaysInMonth: getWeekends(state.selectedDate)
        })
    }, [state.selectedDate]);

    const getPageContent = () => {
        getAllCompanyWorkTimeIntervalsHandler(companyId).then(workTimeIntervalsResponse => {
            getDateWorkTimeIntervalsFromCompanyHandler(companyId, getFirstDayOfMonth(state.selectedDate).toISOString(), getLastDayOfMonth(state.selectedDate).toISOString()).then(dateWorkTimeIntervalsResponse => {
                getAllEmployeesByCompanyIdHandler(companyId, state.selectedDate).then(employeesResponse => {
                    getAllLocationsForCompanyHandler(companyId).then(locationsResponse => {
                        const callback = () => {
                            setState({
                                ...state,
                                loading: false,
                                numOfDaysInMonth: getTotalDaysInMonth(state.selectedDate),
                                weekendDaysInMonth: getWeekends(state.selectedDate),
                                allEmployees: employeesResponse.data.allEmployees.employees,
                                allSchedules: workTimeIntervalsResponse.data.schedules,
                                allDateWorkTimeIntervals: dateWorkTimeIntervalsResponse.data.schedules,
                                allLocations: locationsResponse.data.locations,
                                selectedWorkHoursPeriodFromCalendar: null,
                                scheduleCalendarIsVisible: false,
                                selectedSchedule: null,
                                assignWorkPatternPopupVisible: false,
                                selectedRowIds: [],
                                selectedEmployee: null,
                                selectedDay: null,
                                isDeletePatternAlertOpen: false,
                                name: workTimeIntervalsResponse.data.companyName
                            })
                        }

                        if (type !== TYPES.SUPER_ADMIN) {
                            localeContext.setLocale(StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE), callback);
                        }else{
                            callback();
                        }
                    })
                })
            })
        })
    }

    const getScheduleForEmployee = (index, employee) => {

    }


    const changeActiveTab = (tab) => {
        setState({
            ...state,
            activeTab: tab
        })
    }



    const handleOnChange = (e, input) => {

        if (input === "searchEmployee") {
            setState({
                ...state,
                searchedEmployee: e.target.value
            })
        }else if (input === "location") {

            setState({
                ...state,
                selectedLocation: e !== null ? e : null,
            })
        }else if (input === "groupedByLocation") {
            setState({
                ...state,
                groupedByLocation:!state.groupedByLocation
            })
        }
    }

    const getAvailableLocations = () => {
        const locs = state.allLocations.map(employee => {
            return {
                label: employee.name,
                value: employee.id
            }
        });
        locs.unshift({
            label: <FormattedMessage id="All" defaultMessage="All" />,
            value: null
        });
        return locs;

    }

    const getFilteredEmployees = () => {
        const clockingInfosFilteredByName = state.allEmployees.filter(e => state.searchedEmployee == null || state.searchedEmployee.length == 0 || ((e.name ? e.name : "")).toLowerCase().indexOf(state.searchedEmployee.toLowerCase()) >= 0);
        const clockingInfosFinalFiltered = clockingInfosFilteredByName.map(e => {
            let dateMap = {};
            state.allDateWorkTimeIntervals.filter(dateWorkTimeIntervals => dateWorkTimeIntervals.employeeId === e.id).forEach(dateWorkTimeIntervals => {
                dateMap["" + new Date(dateWorkTimeIntervals.date).getTime()] = dateWorkTimeIntervals;
            });

            const newWorkSchedule = e.workSchedule?.map(day => {
                const existentDate = dateMap[""+day.date.getTime()];
                if(dateMap[""+day.date.getTime()]){
                    return {
                      ...day,
                        isEdited:true,
                        editedDateId: existentDate.id,
                        dayIntervals: existentDate.dayIntervals.length> 0?existentDate.dayIntervals:[]
                    };
                }else{
                    return day;
                }
            });
            return {
                ...e,
                workSchedule: newWorkSchedule
            }
        });
        if (state.selectedLocation?.value!== null) {
            return clockingInfosFinalFiltered.filter(finalClocking => finalClocking.clockingLocations.includes(state.selectedLocation.value));
        }else{
            return clockingInfosFinalFiltered;
        }
    }



    const showEditableInputsForSelectedDay = (selectedEmployee, selectedDay, e) => {

        if (!employeeCanBeEdited(selectedEmployee, true)) {
          return;
        }




        const newState = {
            ...state,
            selectedEmployee: selectedEmployee.id,
            selectedDay: selectedDay,
            hasErrors: false,

        }

        setState(newState);

        if (state.selectedDay !== selectedDay || state.selectedEmployee !== selectedEmployee.id) {
            if (state.editedClockingReportHoursFrom === null &&
                state.editedClockingReportMinutesFrom === null &&
                state.editedClockingReportHoursTo === null &&
                state.editedClockingReportMinutesTo === null) {
                setState(newState);
                return;
            }
        }

        if (state.selectedDay !== selectedDay || state.selectedEmployee !== selectedEmployee.id) {
            const oldValues = getFilteredEmployees().filter(e => e.id == state.selectedEmployee)[0]?.workSchedule[state.selectedDay].dayIntervals;
            if (oldValues && oldValues.length > 0) {
                const interval = oldValues[0];
                const firstHourMin = roundToHoursAndMinutes(interval.from);
                const lastHourMin = roundToHoursAndMinutes(interval.to);
                if (newState.editedClockingReportMinutesFrom == null) {
                    newState.editedClockingReportMinutesFrom = firstHourMin.m;
                }
                if (newState.editedClockingReportHoursFrom == null) {
                    newState.editedClockingReportHoursFrom = firstHourMin.h;
                }
                if (newState.editedClockingReportMinutesTo == null) {
                    newState.editedClockingReportMinutesTo = lastHourMin.m;
                }
                if (newState.editedClockingReportHoursTo == null) {
                    newState.editedClockingReportHoursTo = lastHourMin.h;
                }
                if (firstHourMin.m != parseInt(newState.editedClockingReportMinutesFrom) || firstHourMin.h != parseInt(newState.editedClockingReportHoursFrom) ||
                    lastHourMin.m != parseInt(newState.editedClockingReportMinutesTo) || lastHourMin.h != parseInt(newState.editedClockingReportHoursTo)
                ) {
                    newState.hasErrors = false;
                    setState(newState);
                    saveIntervalChanges(newState);
                }
            } else {
                setState(newState);
                saveIntervalChanges(newState);
            }
        }
    }



    const saveIntervalChanges = (newState) => {
        if (state.selectedEmployee !== null) {

            if (state.editedClockingReportMinutesFrom > 59 ||
                state.editedClockingReportMinutesTo > 59 ||
                state.editedClockingReportHoursFrom > 24 ||
                state.editedClockingReportHoursTo > 24 ||
                state.editedClockingReportHoursTo < 0 ||
                state.editedClockingReportHoursFrom < 0 ||
                state.editedClockingReportHoursTo === "" ||
                state.editedClockingReportMinutesTo < 0 ||
                state.editedClockingReportMinutesFrom < 0 ||
                state.editedClockingReportMinutesTo === "" ||
                state.editedClockingReportHoursFrom == 24 && state.editedClockingReportMinutesFrom > 0 ||
                state.editedClockingReportHoursTo == 24 && state.editedClockingReportMinutesTo > 0) {
                setState({
                    ...state,
                    hasErrors: true,
                    errorMsg: <FormattedMessage id="Error.timeMustBeInCorrectFormat"/>
                })
                return;
            } else if (newState.editedClockingReportHoursFrom > newState.editedClockingReportHoursTo && newState.editedClockingReportHoursTo !== null ||
                (newState.editedClockingReportHoursFrom === newState.editedClockingReportHoursTo &&
                    newState.editedClockingReportMinutesFrom > newState.editedClockingReportMinutesTo)) {
                setState({
                    ...state,
                    hasErrors: true,
                    errorMsg: <FormattedMessage id="Error.clockInTimeMustBeBeforeClockOutTime"
                                                defaultMessage="Error.clockInTimeMustBeBeforeClockOutTime"/>
                })
                return;
            }

            let totalMillisFrom = newState.editedClockingReportMinutesFrom * 60000 + newState.editedClockingReportHoursFrom * 60 * 60000;
            let totalMillisTo = newState.editedClockingReportHoursTo !== null && newState.editedClockingReportMinutesTo !== null ? newState.editedClockingReportMinutesTo * 60000 + newState.editedClockingReportHoursTo * 60 * 60000 : -1;

            // let selectedDate = new Date(state.selectedDate);
            // selectedDate.setDate(state.selectedDay +1);
            const workDate = addDateWithFields(state.selectedDate, {"day":state.selectedDay});

            const bodyIntervalReport = {
                "companyId": companyId,
                "datesWorkTimeIntervals":[{
                    "employeeId": state.selectedEmployee,
                    "date": workDate,
                    "dayIntervals":[{
                        "from": totalMillisFrom,
                        "to": totalMillisTo
                    }]
                }],
            }

            editDateWorkTimeIntervalsHandler(bodyIntervalReport).then(response => {

                if (response.data.error == null) {

                        getDateWorkTimeIntervalsFromCompanyHandler(companyId, getFirstDayOfMonth(state.selectedDate).toISOString(), getLastDayOfMonth(state.selectedDate).toISOString()).then(dateWorkTimeIntervalsResponse => {
                        const listOfEditedEmployees = [...state.listOfEditedEmployees];

                            if (!listOfEditedEmployees.includes(state.selectedEmployee)) {
                                listOfEditedEmployees.push(state.selectedEmployee);
                            }

                        setState({
                            ...newState,
                            loading: false,

                            allDateWorkTimeIntervals: dateWorkTimeIntervalsResponse.data.schedules,
                            editedClockingReportMinutesFrom: null,
                            editedClockingReportHoursFrom: null,
                            editedClockingReportMinutesTo: null,
                            editedClockingReportHoursTo: null,
                            listOfEditedEmployees: listOfEditedEmployees,
                            hasErrors: false
                        })
                    })

                }
            })
        }
    }


    const deleteWorkForSelectedDay = () => {

        const workDate = addDateWithFields(state.selectedDate, {"day":state.selectedDay});

        const bodyIntervalReport = {
            "companyId": companyId,
            "datesWorkTimeIntervals":[{
                "employeeId": state.selectedEmployee,
                "date": workDate,
                "dayIntervals":[]
            }],
        }

        editDateWorkTimeIntervalsHandler(bodyIntervalReport).then(response => {

            if (response.data.error == null) {


                getDateWorkTimeIntervalsFromCompanyHandler(companyId, getFirstDayOfMonth(state.selectedDate).toISOString(), getLastDayOfMonth(state.selectedDate).toISOString()).then(dateWorkTimeIntervalsResponse => {
                    const listOfEditedEmployees = [...state.listOfEditedEmployees];

                    if (!listOfEditedEmployees.includes(state.selectedEmployee)) {
                        listOfEditedEmployees.push(state.selectedEmployee);
                    }
                    setState({
                        ...state,
                        loading: false,
                        allDateWorkTimeIntervals: dateWorkTimeIntervalsResponse.data.schedules,
                        editedClockingReportMinutesFrom: null,
                        editedClockingReportHoursFrom: null,
                        editedClockingReportMinutesTo: null,
                        editedClockingReportHoursTo: null,
                        listOfEditedEmployees: listOfEditedEmployees,
                        hasErrors: false,
                        selectedEmployee:null
                    })
                })

            }
        })
    }
    const saveIntervalsInState = (e, type) => {
        if (type === "hoursFrom") {
            setState({
                ...state,
                editedClockingReportHoursFrom: e.target.value,
                hasErrors: false
            })
        } else if (type === "minutesFrom") {

            setState({
                ...state,
                editedClockingReportMinutesFrom: e.target.value,
                hasErrors: false
            })
        } else if (type === "hoursTo") {
            setState({
                ...state,
                editedClockingReportHoursTo: e.target.value,
                hasErrors: false
            })
        } else if (type === "minutesTo") {
            setState({
                ...state,
                editedClockingReportMinutesTo: e.target.value,
                hasErrors: false
            })
        }
    }

    const toggleInfoToolTip = () => {
        setState({
            ...state,
            infoToolTipVisible: !state.infoToolTipVisible
        })
    }


    const changeOrDeselectCell = (e, type) => {

        if (e.key === 'Enter') {
            const currentEl = e.target.closest("td");
            const closest = currentEl.nextSibling;
            closest.focus();
        }

        if (e.key === 'Escape') {
            deselectCell();
        }
        if (e.key === "ArrowLeft") {
            const currentEl = e.target.closest("td");
            const closest = currentEl.previousSibling;
            closest.focus();
        }

        if (e.key === "ArrowRight") {
            const currentEl = e.target.closest("td");
            const closest = currentEl.nextSibling;
            closest.focus();
        }

        if (e.key === "ArrowUp") {
            e.preventDefault();
            let currentTabIndex = e.target.closest("td").tabIndex - state.numOfDaysInMonth;
            let closest = document.querySelectorAll("[tabIndex='" + currentTabIndex + "']");

            if (closest.length > 0) {
                closest[0].focus();
            } else {
                return;
            }
        }

        if (e.key === "ArrowDown") {
            e.preventDefault();
            let currentTabIndex = e.target.closest("td").tabIndex + state.numOfDaysInMonth;
            let closest = document.querySelectorAll("[tabIndex='" + currentTabIndex + "']");
            if (closest.length > 0) {
                closest[0].focus();
            } else {
                return;
            }
        }
    }


    const getIndex = (trIndex, tdIndex) => {
        if (trIndex === 0) {
            return tdIndex;
        } else if (trIndex === 1) {
            return state.numOfDaysInMonth + tdIndex;
        } else {
            return state.numOfDaysInMonth * trIndex + tdIndex;
        }
    }


    const deselectCell = () => {
        setState({
            ...state,
            selectedDay: null,
            selectedEmployee: null,
            editedHourlyReportHours: null,
            editedHourlyReportMinutes: null,
            editedClockingReportMinutesFrom: null,
            editedClockingReportHoursFrom: null,
            editedClockingReportMinutesTo: null,
            editedClockingReportHoursTo: null,
            hasErrors: false
        })
    }

    const employeeCanBeEdited = (employee, specialCaseForManager) => {
        if (type === TYPES.SUPER_ADMIN) { // super admin can edit everyone
            return true
        }
        if (employee.type === TYPES.WORKER) { // workers can be edited by everyone
            return true;
        } else if ((employee.type === TYPES.ADMIN && employee.id === StorageService.getItem(LOCALSTORAGE_ITEMS.USER_ID)) ||
            (employee.type === TYPES.MANAGER && employee.id === StorageService.getItem(LOCALSTORAGE_ITEMS.USER_ID) && specialCaseForManager === true) ||
            (employee.type === TYPES.MANAGER && StorageService.getItem(LOCALSTORAGE_ITEMS.TYPE) === TYPES.ADMIN)){
            return true;
        } else{
            return false;
        }
    }

    const onSelectSingleRowId = (employee) => {
        if (employeeCanBeEdited(employee)) {
            let newSelectedRowIds = [...state.selectedRowIds];
            if (newSelectedRowIds.includes(employee.id)) {
                newSelectedRowIds = newSelectedRowIds.filter(item => item !== employee.id)
            } else {
                newSelectedRowIds.push(employee.id);
            }
            setState({
                ...state,
                selectedRowIds: newSelectedRowIds
            })
        }

    };

    const onSelectAllRowsIds = (e, rowIds) => {
        let newSelectedRowIds = [...state.selectedRowIds];
        if (JSON.stringify(newSelectedRowIds) === JSON.stringify(rowIds)) {
            newSelectedRowIds = [];
        } else {
            newSelectedRowIds = rowIds;
        }

        setState({
            ...state,
            selectedRowIds: newSelectedRowIds,
            assignWorkPatternPopupVisible: false
        })
    };

    const openWorkPatternPopup = (e, employee) => {
        e.preventDefault();
        if(employeeCanBeEdited(employee)){
            let newSelectedRowIds = [...state.selectedRowIds];
            if (!newSelectedRowIds.includes(employee.id)) {
                newSelectedRowIds.push(employee.id);
            }

            setState({
                ...state,
                assignWorkPatternPopupVisible: true,
                assignWorkPatternPopupPosition: {
                    left: e.clientX,
                    top: e.clientY
                },
                activeScheduleForEmployee: state.selectedRowIds.length > 1 ? null : employee?.workScheduleDetails,
                selectedRowIds: newSelectedRowIds
            })
        }
    }

    const closeWorkPatternPopup = (e) => {
        e.preventDefault();
            setState({
                ...state,
                assignWorkPatternPopupVisible: false,
                assignWorkPatternPopupPosition:null,
                activeScheduleForEmployee: null,
            })
    }

    const openCalendar = () => {
        setState({
            ...state,
            calendarIsVisible: true
        });
    }

    const closeCalendar = () => {
        setState({
            ...state,
            calendarIsVisible: false
        });
    }

    const handleCalendarDatePick = (e) => {
        setState({
                ...state,
                loading: true,
                selectedDate: e,
                calendarIsVisible: false
            }
        );
    }

    const openScheduleCalendar = (selectedScheduleId) => {
        setState({
            ...state,
            selectedSchedule: selectedScheduleId,
            scheduleCalendarIsVisible: true
        });
    }

    const closeScheduleCalendar = () => {
        setState({
            ...state,
            scheduleCalendarIsVisible: false,
            assignWorkPatternPopupVisible: false,
            selectedSchedule: null
        });
    }

    const handleSelectedWorkHoursPeriodFromCalendar = (e) => {
        setState({
                ...state,
                selectedWorkHoursPeriodFromCalendar: e,
            }
        );
    }

    const openDeletePatternAlert = () => {
        setState({
            ...state,
            assignWorkPatternPopupVisible:false,
            isDeletePatternAlertOpen: true,
        });
    }

    const hideAlert = () => {
        setState({
            ...state,
            assignWorkPatternPopupVisible:false,
            isDeletePatternAlertOpen: false,
        })
    }


    const getAlert = () =>{
        if(state.isDeletePatternAlertOpen) {
            return {
                title: <FormattedMessage id="DoYouWantToResetSchedule"/>,
                deleteAction1: () => resetPattern(state.selectedFacility),
                deleteText1: <FormattedMessage id="ResetSchedule"/>,
                cancelAction: () => hideAlert(),
                cancelText: <FormattedMessage id="Cancel" defaultMessage="Cancel"/>,
                hideAlert: () => hideAlert()
            }
        }
    }

    const getEditedEmployees = () => {

        getAllEmployeesByCompanyIdHandler(companyId, state.selectedDate).then(employeesResponse => {
            const listOfEditedEmployees = [...state.listOfEditedEmployees];
            state.selectedRowIds.forEach(employeeId=>{
                if (!listOfEditedEmployees.includes(employeeId)) {
                    listOfEditedEmployees.push(employeeId);
                }
            })
            setState({
                ...state,
                loading: false,
                allEmployees: employeesResponse.data.allEmployees.employees,
                selectedWorkHoursPeriodFromCalendar: null,
                scheduleCalendarIsVisible: false,
                selectedSchedule: null,
                assignWorkPatternPopupVisible: false,
                selectedRowIds: [],
                selectedEmployee: null,
                selectedDay: null,
                isDeletePatternAlertOpen:false,
                listOfEditedEmployees: listOfEditedEmployees,
            })
        })
    }



    const resetPattern = () => {
        const bodyEditAccount = state.selectedRowIds.map(employeeId => {
            return {
                "employeeId": employeeId,
                "workSchedule": {}
            }
        });
        editCompanyEmployeeByIdHandler(bodyEditAccount).then(response => {
            if (!response.data.error) {
                getEditedEmployees();
            } else {
                setState({
                    ...state,
                    errorMsg: response.data.message
                })
            }
        })
    }


    const saveSchedulePattern = () => {
        const bodyEditAccount = state.selectedRowIds.map(employeeId => {
            return {
                "employeeId": employeeId,
                "workSchedule": {
                    "date": state.selectedWorkHoursPeriodFromCalendar || today,
                    "workTimeIntervalsId": state.selectedSchedule
                }
            }
        });


        editCompanyEmployeeByIdHandler(bodyEditAccount).then(response => {
            if (!response.data.error) {
                getEditedEmployees();
            } else {
                setState({
                    ...state,
                    errorMsg: response.data.message
                })
            }
        })
    }

    const onDeleteInterval = (id) => {
        deleteDateWorkTimeIntervalsByIdHandler(id).then(r=>{
                getDateWorkTimeIntervalsFromCompanyHandler(companyId, getFirstDayOfMonth(state.selectedDate).toISOString(), getLastDayOfMonth(state.selectedDate).toISOString()).then(dateWorkTimeIntervalsResponse => {
                        const listOfEditedEmployees = [...state.listOfEditedEmployees];

                        if (!listOfEditedEmployees.includes(state.selectedEmployee)) {
                            listOfEditedEmployees.push(state.selectedEmployee);
                        }
                        setState({
                            ...state,
                            allDateWorkTimeIntervals: dateWorkTimeIntervalsResponse.data.schedules,
                            selectedRowIds: [],
                            selectedEmployee: null,
                            selectedDay: null,
                            listOfEditedEmployees: listOfEditedEmployees
                        })
                })
        })
    }

    const sendNotification = () => {
        let body = [];
        if (state.selectedRowIds.length > 0) {
            state.selectedRowIds.forEach(rowId=>{
                body.push({
                    "companyId": companyId,
                    "employeeId": rowId,
                })
            })

            updateScheduleNotificationsHandler(body).then(response=>{
                if (response.data.error == null) {
                    setState({
                        ...state,
                        confirmationModalVisible: true,
                        selectedRowIds:[],
                        errorMsg: null,

                    })
                    setTimeout(function () {
                        setState({
                            ...state,
                            confirmationModalVisible: false,
                            selectedRowIds:[],
                        })
                    }, 2000);
                }else{
                    setState({
                        ...state,
                        confirmationModalVisible: false,
                        errorMsg: response.data.message,
                    })
                }
            });

        }else{
            openNotifyEmployeesPopup();
        }
    }

    const openNotifyEmployeesPopup = () => {
        setState({
            ...state,
            notifyEmployeesPopupVisible: true
        })
    }

    const closeNotifyEmployeesPopup = () => {
        setState({
            ...state,
            notifyEmployeesPopupVisible: false
        })
    }

    const closeConfirmationModal = () => {
        setState({
            ...state,
            confirmationModalVisible: false
        })
    }


    return state.loading ?
        <Spinner/>
        : (
            <div className="content">
                <Header
                    alert={getAlert()}
                    companyId={companyId}
                    hasProfileBtn={true}
                    hasBackBtn={type !== TYPE.MANAGER && type !== TYPE.ADMIN}
                    headerTitle={type===TYPES.SUPER_ADMIN? state.name : <FormattedMessage id="Scheduler" defaultMessage="Scheduler"/>}
                />

                <div className="inner-content inner-content-for-table">
                    <TabNavigation
                        companyId={companyId}
                        activeTab={type === TYPE.SUPER_ADMIN ? 6 : 0}
                        page="scheduler"
                    />

                    <section className="row top-actions-row">

                        <div className="form-group search">
                            <div className="row">
                                <label className="label">
                                    <FormattedMessage id="SearchEmployee" defaultMessage="SearchEmployee"/>
                                </label>

                            </div>
                            <div className="row">
                                <FormattedMessage id="Search" defaultMessage="Search">
                                    {placeholder =>
                                        <input
                                            className="input"
                                            placeholder={placeholder}
                                            onChange={e => handleOnChange(e, "searchEmployee")}

                                        />
                                    }
                                </FormattedMessage>
                            </div>
                        </div>

                        <div className="form-group select-form-group">
                            <div className="row">
                                <label className="label">
                                    <FormattedMessage id="Location"/>
                                </label>

                            </div>
                            <div className="row select-row">
                                <FormattedMessage id="All" defaultMessage="All">
                                    {placeholder =>
                                        <Select
                                            tabIndex={-1}
                                            className={state.hasSelectErrors ? "select with-errors" : "select"}
                                            options={getAvailableLocations()}
                                            onChange={e => handleOnChange(e, "location")}
                                            value={state.selectedLocation}
                                            placeholder={placeholder}
                                            noOptionsMessage={() => <FormattedMessage id="NoOptionsAvailable"/>}
                                            id="location"
                                            styles={selectStyles}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                        />
                                    }
                                </FormattedMessage>
                                {state.hasSelectErrors && <div className="error-div-for-select">
                                    <FormattedMessage id="SelectOneLocationToDownloadReport"
                                                      defaultMessage="SelectOneLocationToDownloadReport"/>
                                </div>}
                            </div>
                        </div>

                        <div className="form-group ml-50">
                            <div className="row">
                                <label className="label">
                                    <FormattedMessage id="Grouping"/>
                                </label>

                            </div>

                            <div className="grouping-row">

                                <div className="group-options">
                                    <div className="group-item" onClick={(e) => handleOnChange(e, "groupedByLocation")}>
                                        <label className="label"><FormattedMessage id="GroupByLocation"/></label>
                                        <div className="checkmarks">
                                            <div className="checkmark-item">
                                                <input onChange={(e) => (console.log(e))} checked={state.groupedByLocation} type="checkbox" id="locationGrouped" name="locationGrouped" />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="form-group calendar-form-group">
                            <div className="row centered-x">
                                <label className="label">
                                    <FormattedMessage id="Month"/>
                                </label>
                            </div>
                            <div className="row">
                                <a onClick={openCalendar} className="link calendar-link">
                                    <FormattedDate
                                        value={state.selectedDate}
                                        month="long"
                                        timeZone={StorageService.getItem(LOCALSTORAGE_ITEMS.TIMEZONE)}
                                        locale={StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE)}
                                    />
                                </a>
                            </div>
                        </div>

                        <div className="row ml-auto buttons-with-margin">
                            <button tabIndex={-1} className="btn btn-success"
                                    onClick={() => sendNotification()}
                            >
                                <FormattedMessage id="NotifyEmployees" defaultMessage="NotifyEmployees"/></button>
                        </div>
                        {state.notifyEmployeesPopupVisible &&
                            <>
                                <div className="notify-employees-popup">
                                    <div className="row title-row">
                                        <FormattedMessage id="SelectAtLeastOneEmployee" defaultMessage="SelectAtLeastOneEmployee"/>
                                    </div>
                                    <div className="row">
                                        <FormattedMessage id="EmployeesWithUpdatedWorkHours" defaultMessage="EmployeesWithUpdatedWorkHours"/>
                                    </div>
                                    <div className="row example-row">
                                        <i className="icon middot-icon">
                                            {middotIcon}
                                        </i>
                                        <div className="checkbox">
                                            <input type="checkbox"
                                                   defaultChecked={false}/>
                                            <div className="checksquare"></div>
                                        </div>
                                        John Doe
                                        <i className="icon ml-auto">{downArrowIcon}</i>
                                    </div>
                                    <div className="row buttons-row">
                                       <span className="simple-text" onClick={() => closeNotifyEmployeesPopup()}> <FormattedMessage id="Ok"/></span>
                                    </div>
                                </div>

                                <div className="blurry-background" onClick={() => closeNotifyEmployeesPopup()}></div>
                            </>
                        }


                    </section>
                    <ScrollContainer
                        hideScrollbars={false}
                        activationDistance={1}
                        ignoreElements={".prevent-scroll"}
                        className="scroll-container scheduler-scroll-container"
                    >

                        <WorkHoursTable
                            allLocations={state.allLocations}
                            assignWorkPatternPopupVisible={state.assignWorkPatternPopupVisible}
                            assignWorkPatternPopupPosition={state.assignWorkPatternPopupPosition}
                            listOfEditedEmployees={state.listOfEditedEmployees}
                            openWorkPatternPopup={openWorkPatternPopup}
                            closeWorkPatternPopup={closeWorkPatternPopup}
                            onSelectSingleRowId={onSelectSingleRowId}
                            onSelectAllRowsIds={onSelectAllRowsIds}
                            selectedRowIds={state.selectedRowIds}
                            openScheduleCalendar={openScheduleCalendar}
                            selectedSchedule={state.selectedSchedule}
                            activeScheduleForEmployee={state.activeScheduleForEmployee}
                            handleSelectedWorkHoursPeriodFromCalendar={handleSelectedWorkHoursPeriodFromCalendar}
                            allEmployees={getFilteredEmployees()}
                            allSchedules={state.allSchedules}
                            numOfDaysInMonth={state.numOfDaysInMonth}
                            selectedEmployee={state.selectedEmployee}
                            selectedDay={state.selectedDay}
                            selectedDate={state.selectedDate}
                            weekendDaysInMonth={state.weekendDaysInMonth}
                            hasErrors={state.hasErrors}
                            errorMsg={state.errorMsg}
                            handleKeyDown={changeOrDeselectCell}
                            saveIntervalsInState={saveIntervalsInState}
                            showEditableInputsForSelectedDay={showEditableInputsForSelectedDay}
                            getIndex={getIndex}
                            openDeletePatternAlert={openDeletePatternAlert}
                            onDeleteInterval={onDeleteInterval}
                            employeeCanBeEdited={employeeCanBeEdited}
                            activeTab={state.activeTab}
                            changeActiveTab={changeActiveTab}
                            selectedHolidayType={state.selectedHolidayType}
                            deleteWorkForSelectedDay={deleteWorkForSelectedDay}
                            groupedByLocation={state.groupedByLocation}

                        />

                        <div onClick={() => toggleInfoToolTip()} className="info-container">
                            <>
                                {state.infoToolTipVisible &&
                                <div className="tooltip">
                                    <div className="close-tooltip-btn"
                                         onContextMenu={(e) => e.preventDefault()}
                                         onClick={(e) => toggleInfoToolTip()}>
                                                 <span className="close-icon">
                                                     {closeIcon}
                                                 </span>
                                    </div>
                                    <div className="arrow-down"></div>
                                    <div className="row colour-one">
                                        {/*<div className="col colour-col"></div>*/}
                                        <div className="col text-col"><FormattedMessage
                                            id="EditedInterval"/></div>
                                    </div>
                                    <div className="row colour-two">
                                        {/*<div className="col colour-col"></div>*/}
                                        <div className="col text-col"><FormattedMessage
                                            id="GeneratedInterval"/></div>
                                    </div>
                                </div>
                                }
                                <div className="info-icon">?</div>
                            </>
                        </div>
                    </ScrollContainer>

                    <div
                        className={state.confirmationModalVisible ? "visible modal confirmation-modal" : "modal confirmation-modal"}>
                               <span
                                   onClick={() => closeConfirmationModal()}
                                   className="ml-auto close-icon">
                                    {closeIcon}
                                </span>
                        <div className="modal-content"><FormattedMessage
                            id="NotificationSent"/></div>
                    </div>


                    {state.calendarIsVisible &&
                    <>
                        <div onClick={closeCalendar}
                             className={!state.calendarIsVisible ? "blurry-background hidden" : "blurry-background"}></div>
                        <div className="calendar-container">
                            <CalendarTmz
                                showNavigation={true}
                                locale={StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE)}
                                className={"custom-calendar"}
                                view="year"
                                onClickMonth={handleCalendarDatePick}
                                defaultValue={state.selectedDate}
                                tz={StorageService.getItem(LOCALSTORAGE_ITEMS.TIMEZONE)}
                            />
                        </div>
                    </>

                    }

                    {state.scheduleCalendarIsVisible &&
                    <>
                        <div onClick={closeScheduleCalendar}
                             className={!state.scheduleCalendarIsVisible ? "blurry-background hidden" : "blurry-background"}></div>
                        <div className="calendar-container">
                            <div className="calendar-header row">
                                <FormattedMessage id="WorkHoursPeriod"/>
                                <span
                                    onClick={() => closeScheduleCalendar()}
                                    className="ml-auto close-icon">
                                    {closeIcon}
                                </span>
                            </div>

                            <CalendarTmz
                                showNavigation={true}
                                locale={StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE)}
                                className={"scheduler-calendar custom-calendar"}
                                view="month"
                                onClickDay={handleSelectedWorkHoursPeriodFromCalendar}
                                defaultValue={state.selectedDate}
                                selectRange={false}
                                showNeighboringMonth={true}
                                showFixedNumberOfWeeks={true}
                                tz={StorageService.getItem(LOCALSTORAGE_ITEMS.TIMEZONE)}
                            />

                            <div className="calendar-footer">
                                <div className="row buttons-container">
                                    <button onClick={() => closeScheduleCalendar()} className="btn btn-default">
                                        <FormattedMessage
                                            id="Cancel" defaultMessage="Cancel"/></button>
                                    <button onClick={(e) => saveSchedulePattern()} className="btn btn-success">
                                        <FormattedMessage
                                            id="Save" defaultMessage="Save"/></button>
                                </div>
                            </div>
                        </div>
                    </>

                    }

                </div>

            </div>
        );
}

export default WorkHoursAll;
