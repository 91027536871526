import React, {useEffect, useRef, useState} from "react";
import {FormattedMessage} from "react-intl";
import SchedulerPanelInterval from "./SchedulerPanelInterval";
import {colors} from "../../util/Constants";


function SchedulerPanel(props) {
    const onClickScheduler = () => {
        props.setSelectedScheduler(props.schedulerId)
    }

    const onDeleteScheduler = (e) => {
        e.stopPropagation();
        props.deleteScheduler(props.schedulerId);
    }

    const onInputChange = (inputType, e) => {
        e.stopPropagation();
        props.saveIntervalsInState(inputType, e.target.value, props.schedulerId, props.index);
    }


    return (
            <div
                index={props.index}
                style={{
                    borderLeft: "10px solid " +  props.color
                }}
                onClick={() => onClickScheduler()}
                className={(props.selectedScheduler && props.selectedScheduler === props.schedulerId ? "scheduler-panel active" : "scheduler-panel") + (props.isViewOnlyMode ? " view-only-mode" : "") + (props.daysIntervalsErrorIndex ? " has-errors" : "")}>
                <div className="scheduler-title">
                    <section className="form-container">
                        <div className="form with-big-inputs">
                            <div className="form-group">
                                <div className="row">
                                    <label className="label" htmlFor="name"> <FormattedMessage
                                        id="ScheduleName"/></label>
                                </div>
                                <div className="row">
                                    <FormattedMessage id="ScheduleName" defaultMessage="ScheduleName">
                                        {placeholder =>
                                            <input
                                                readOnly={props.isViewOnlyMode}
                                                className="prevent-scroll input"
                                                type="text"
                                                onChange={(e) => {onInputChange("schedulerName", e)}}
                                                maxLength={80}
                                                placeholder={placeholder}
                                                value={props.schedulerName}
                                                autoComplete="off"
                                                id="name"
                                            />
                                        }
                                    </FormattedMessage>
                                    {props.isViewOnlyMode === false && <a data-qa={props.schedulerName ? props.schedulerName: "delete-new-scheduler"} className="delete-scheduler-btn btn btn-link text-danger"
                                       onClick={(e) => {onDeleteScheduler(e)}}>
                                        <FormattedMessage id="Delete"/>
                                    </a>}
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
                <div className="row scheduler-panel-intervals-container">
                    {
                        props.schedulerIntervals?.length > 0 ?
                            props.schedulerIntervals.map((schedulerInterval, index) => (
                                <SchedulerPanelInterval
                                    key={index}
                                    index={index}
                                    schedulerId={props.schedulerId}
                                    from={schedulerInterval.dayIntervals[0]?.from && schedulerInterval.dayIntervals[0]?.from !== ":" ? schedulerInterval.dayIntervals[0]?.from : null} //TODO fix
                                    to={schedulerInterval.dayIntervals[0]?.to && schedulerInterval.dayIntervals[0]?.to !== ":" ? schedulerInterval.dayIntervals[0]?.to : null} //TODO fix
                                    deleteSchedulerInterval={props.deleteSchedulerInterval}
                                    selectedScheduler={props.selectedScheduler}
                                    selectedIntervalIndex={props.selectedIntervalIndex}
                                    showEditableInputs={props.showEditableInputs}
                                    showEditableInputsForInterval={props.showEditableInputsForInterval}
                                    unselectInterval={props.unselectInterval}
                                    saveIntervalsInState={props.saveIntervalsInState}
                                    isViewOnlyMode={props.isViewOnlyMode}
                                    addSchedulerInterval={props.addSchedulerInterval}
                                />
                            ))
                            :
                            null
                    }
                    {props.isViewOnlyMode === false && <div onClick={(e) => {e.stopPropagation();props.addSchedulerInterval(props.schedulerId);}}
                         className="btn btn-link add-interval-btn">
                        <span className="icon">+</span><FormattedMessage id="NewSlot"/>
                    </div>}


                </div>
            </div>



    );
}


export default SchedulerPanel;
